import { Link } from "react-router-dom";
const customStyle={
    backgroundImage: "url('/img/carousel-2.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize:"cover",
    backgroundPosition:"center",
    height:"60vh"
  }
export default function Services(){
    return(
        <>
             {/* Page Header Start */}
        <div style={customStyle} className="page-header">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Service</h2>
                    </div>
                    <div className="col-12">
                        <Link to="/">Home</Link>
                        <Link to="/services">Service</Link>
                    </div>
                </div>
            </div>
        </div>
        {/* Page Header End */}
    
        {/* Service Start */}
        <div className="service">
            <div className="container">
                <div className="section-header text-center">
                    <p>What We Do?</p>
                    <h2>Premium Washing Services</h2>
                </div>
                <div className="row animate__animated animate__fadeIn animate__slow animate__repeat-1 animate__delay-1s">
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <i className="flaticon-car-wash-1"></i>
                            <h3>Exterior Washing</h3>
                            <p>"Wash away the dirt and grime for a shiny exterior!"</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <i className="flaticon-car-wash"></i>
                            <h3>Interior Washing</h3>
                            <p>“We don’t cut corners, we clean them.”</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <i className="flaticon-vacuum-cleaner"></i>
                            <h3>Vacuum Cleaning</h3>
                            <p>“Swipe Away the Dust with a Vacuum”</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <i className="flaticon-seat"></i>
                            <h3>Seats Washing</h3>
                            <p>“We leave every space cleaner than we found it.”</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <i className="flaticon-car-service"></i>
                            <h3>Window Wiping</h3>
                            <p>"No window is too dirty for us to clean!"</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <i className="flaticon-car-service-2"></i>
                            <h3>Wet Cleaning</h3>
                            <p>Get the dust and dirt clean in a few seconds.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <i className="flaticon-car-wash"></i>
                            <h3>Odour Treatment</h3>
                            <p>You Love Your Car, We Love Cleaning It.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <i className="flaticon-brush-1"></i>
                            <h3>Tyre Dressing & Cleaning </h3>
                            <p>Fast and friendly car wash</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Service End */}
        
       
        </>
    )
}