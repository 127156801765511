import { useEffect, useState } from "react"
import { RingLoader } from "react-spinners"
import Apiservices from "../API/Apiservices"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

export default function PriceUpdate() {
const[load ,setload]=useState(false)
const [name,setname]=useState("")
const [actualprice,setactualprice]=useState("")
const [deletedprice,setdeleteprice]=useState("")
const param=useParams()
const id=param.id
console.log(id)
useEffect(()=>{
    let data={
        _id:id
    }
    Apiservices.planSingle(data).then((res)=>{
        setname(res.data.data.planname)
        setdeleteprice(res.data.data.deletedprice)
        setactualprice(res.data.data.actualprice)
    }).catch((err)=>{
        toast.error("error"+err)
    })
},[load])
const changePlan=(e)=>{
    e.preventDefault()
    setload(true)
    let data={
        _id:id,
        name:name,
        deletedprice:deletedprice,
        actualprice:actualprice
    } 
    Apiservices.planUpdate(data).then((res)=>{
        if(res.data.success==true){
            setTimeout(() => {
                toast.success(res.data.message)
                setload(false)
            }, 3000);
        }else{
            setTimeout(() => {
                toast.error(res.data.message)
                setload(false)
            }, 3000)
        }
    }).catch((err)=>{
        toast.error("error"+err)
    })
}   
const customStyle={
    position:"Absolute",
    top:"50%",
    left:"48%",
    zIndex:1
}
    return (
        <>
            <RingLoader color="grey" size={100} loading={load} cssOverride={customStyle}/>
            <div className={load==true?"disable-screen":""}>
            <div className="container">
                <p className="text-center fw-bold display-1">Price Update</p>
                <form>
                    <div className="offset-md-3 col-lg-6 col-md-3 mb-3">
                        <label for="exampleInputEmail1" className="form-label">Plan Name</label>
                        <input value={name} onChange={(e)=>{setname(e.target.value)}} type="text" className="form-control" />
                    </div>
                    <div className="offset-md-3 col-lg-6 col-md-3 mb-3">
                        <label className="form-label">DeletedPrice</label>
                        <input value={deletedprice} onChange={((e)=>{setdeleteprice(e.target.value)})} type="text" className="form-control" />
                    </div>
                    <div className="offset-md-3 col-lg-6 col-md-3 mb-3">
                        <label className="form-label">ActualPrice</label>
                        <input value={actualprice} onChange={(e)=>{setactualprice(e.target.value)}} type="text" className="form-control" />
                    </div>
                    <div className="row">
                        <div className="offset-md-3 col-lg-6 col-md-3">
                            <button onClick={changePlan} type="submit" className="btn btn-primary d-block mx-auto w-50">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        
        </>
    )
}