import { useState } from "react"
import Apiservices from "../API/Apiservices"
import { ToastContainer, toast } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { RingLoader } from "react-spinners"

export default function Login(){

  const[name,setname]=useState('')
  const[pass,setpass]=useState('')
  const [load,setload]=useState(false)
  const nav=useNavigate()
  const submitform=(e)=>{
    e.preventDefault()
    setload(true)
    let data={
        email:name,
        password:pass
    }
    Apiservices.login(data).then(
                (res)=>{ 
                    if(res.data.success===true && res.data.data.userType===1){
                        sessionStorage.setItem('id',res.data.data._id)
                        sessionStorage.setItem("token",res.data.token)
                        sessionStorage.setItem("Admin",JSON.stringify(res.data.data))
                        sessionStorage.setItem("userType",res.data.data.userType)
                        console.log(res)
                        setTimeout(()=>{
                            setload(false)
                            toast.success("Welcome Admin") 
                            nav("/admin")
                        },3000)
                    }
                    else if(res.data.success===true && res.data.data.userType===2){
                        sessionStorage.setItem('id',res.data.data._id)
                        sessionStorage.setItem("token",res.data.token)
                        sessionStorage.setItem("userType",res.data.data.userType)
                        sessionStorage.setItem("userData",JSON.stringify(res.data.data))
                        setTimeout(()=>{
                            toast.success(`Welcome`+JSON.parse(JSON.stringify(res.data.data.name)))
                            setload(false)
                            toast.success(res.data.message)    
                            nav("/")
                        },3000)
                    }
                    else{
                        setTimeout(()=>{
                            toast.error(res.data.message)
                            setload(false)
                        },3000)   
                    }
                }
            ).catch((err)=>{
                toast.error("something went wrong"+err)
        })
  }
  const customStyle={
    position:"Absolute",
    left:"45%",
    top:"48%",
    zIndex:1
  }
    return(
        <> 
          <div className={load===true?"disable-screen":""}>  
        <RingLoader loading={load} size={100} color="lime" cssOverride={customStyle} />
          <div  className="container  pt-4">
            <div  className="row ">
                <div className="col-lg-6 col-md-6"><img className="img-fluid animate__animated animate__fadeInUp animate__slow animate__repeat-1 animate__delay-1s" src="/img/carousel-1.jpg" alt="login"/></div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-5 animate__animated animate__fadeInUp animate__slow animate__repeat-1 animate__delay-2s">
                    <div className="row">
                        <div className="fs-1 text-center fw-bold">Login </div>
                    </div>
                    <div className="row mt-4">
                        <div className="offset-md-1  col">
                             <input value={name}  onChange={(e)=>{setname(e.target.value)} }placeholder="Email" className="form-control p-4"/>
                        </div> 
                    </div>
                    <div className="row mt-4">
                        <div className="offset-md-1 col">
                             <input value={pass} onChange={(e)=>{setpass(e.target.value)}} placeholder="Password" className="form-control p-4"/>
                        </div> 
                    </div>
                    <div className="row ">
                        <div className="col-md ">  
                            <button onClick={submitform} className="btn d-block mx-auto   mt-4 btn-custom ">Login </button></div>
                    </div>
                    <div className="row">
                    <div className="col-md d-flex justify-content-center">
                        <Link style={{textDecoration:"none"}} to={"/forgotpassword"} className=" mt-4 d-block mx-auto " >Forget password?</Link>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>  
          <ToastContainer/> 
        </>
    )
}