import { useState } from "react"
import { useEffect } from "react"
import Apiservices from "../API/Apiservices"
import { toast } from "react-toastify"
import moment from "moment"
export default function DashBoard() {
   const [user,setuser]=useState(0)
   const [enquiry,setenquiry]=useState(0)
   const [appointement,setappointment]=useState(0)
   const [x,setx]=useState([])
   useEffect(()=>{
           Apiservices.dashboard().then((item)=>{
            console.log(item.data.data)
                setuser(item.data.data.user)
                setenquiry(item.data.data.enquiry)
                setappointment(item.data.data.appointments)
                setx(item.data.data.appointment)
                toast.success(item.data.message)
           }).catch((err)=>{
                toast.error('Error'+err)
           }) 
   },[])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                <div className="col-lg-4">
                    <div className="card mb-3" style={{maxWidth:"540px"}}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img  src="/img/user.jpeg" className="img-fluid rounded-start p-2" alt="Alluser"/>
                            </div>
                            <div className="col-md-8 my-4">
                                <div className="card-body">
                                    <h5 className="card-title">Total User</h5>
                                    <p className="card-text display-1">{user}</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                    {/* Appointment */}
                <div className="col-lg-4">
                    <div className="card mb-3" style={{maxWidth:"540px"}}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src="/img/logo.png" className="img-fluid rounded-start" alt="appointment"/>
                            </div>
                            <div className="col-md-8 my-4">
                                <div className="card-body">
                                    <h5 className="card-title">Appointments</h5>
                                    <p className="card-text display-1">{appointement}</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                        {/* Feedback */}
                <div className="col-lg-4">
                    <div className="card mb-3" style={{maxWidth:"540px"}}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img  src="https://th.bing.com/th/id/OIP.j-tR7g9-LTHYoMjlkT67yAAAAA?w=182&h=182&c=7&r=0&o=5&dpr=1.3&pid=1.7" className="img-fluid rounded-start" alt="feedbackk"/>
                            </div>
                            <div className="col-md-8 my-4">
                                <div className="card-body">
                                    <h5 className="card-title">Feedback</h5>
                                    <p className="card-text display-1">{enquiry}</p>
                                     </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </div> 
                {/* recent appointment */}
                {/* <p className="text-center display-1">Recent Appointment</p> */}
                <div className="row">
                    <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="table-dark">
                            <tr>
                            <th>Sno.</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>contact</th>
                            <th>plan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {x.map((item,index)=>{
                                return(
                                    <tr>
                                        <td>{index+1}</td>         
                                        <td> {moment(item.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>         
                                        <td>{item.name}</td>        
                                        <td>{item.address}</td>         
                                        <td>{item.mobile}</td>         
                                        <td>{item.plan}</td>         
                                    </tr>
                                )
                            })}  
                        </tbody>
                    </table>
                    </div>   
                </div>
            </div>
        </>
    )
}