import { useEffect, useMemo, useState } from "react"
import Apiservices from "../API/Apiservices"
import { ToastContainer, toast } from "react-toastify"
import { RingLoader } from "react-spinners"

export default function Appointment() {
    const [load, setload] = useState(false)
    const [username, setusername] = useState('')
    const [email, setemail] = useState('')
    const [mobile, setmobile] = useState("")
    const [address, setaddress] = useState('')
    const [plan, setplan] = useState([])
    const [values, setvalues] = useState("")
    const token = sessionStorage.getItem("token")
    useEffect(() => {
        Apiservices.planAll().then((res) => {
            setplan(res.data.data)
        }).catch((err) => {
            toast.error("error:" + err)
        })
    }, [])



    const apptAdd = (e) => {
        e.preventDefault()
        setload(true)
        let formdata = {
            name: username,
            email: email,
            mobile: mobile,
            address: address,
            plan: values
        }
        Apiservices.apptAdd(formdata).then((res) => {
            if (res.data.success == false) {
                setTimeout(() => {
                    setload(false)
                    toast.error("Please Login")
                }, 3000)
            } else {
                setTimeout(() => {
                    setload(false)
                    toast.success(res.data.message)
                }, 3000)
            }
        }).catch((err) => {
            toast.error("error" + err)
        })
    }

    const handleForm = (e) => {
        setvalues(e.target.value)
    }
    useEffect(() => {
        let data = {
            userId: sessionStorage.getItem("id")
        }
        if (!!token) {
            Apiservices.userSingle(data).then((res) => {
                setusername(res.data.data.name)
                setemail(res.data.data.email)
                setmobile(res.data.data.contact)
                setaddress(res.data.data.address)
            }).catch((err) => {
                toast.error("error:" + err)
            })
        }
    }, [])
    const customStyle = {
        position: "Absolute",
        left: "50%",
        top: "50%",
        zIndex: 1
    }
    return (
        <>
            <div className={load == true ? "disable-screen" : ""}>
                <RingLoader loading={load} size={100} cssOverride={customStyle} color="lime" />
                <div className="container my-5 ">
                    <div className="row">
                        <div className="col-lg-6 my-5 animate__animated animate__fadeInLeft animate__slow animate__repeat-1 animate__delay-1s">
                            <img className="img-fluid" height={"200px"} src="/img/carousel-1.jpg" alt="car-image" />
                        </div>
                        <div className="col-lg-6 ">
                            <div className="row">
                                <div className="col-lg-12 fw-bold  ">
                                    <p className="fs-1 text-center">Book Appointment</p>
                                    Name: <input value={username} onChange={(e) => { setusername(e.target.value) }} placeholder="eg. John" type="text" className="form-control" />
                                    E-mail:<input value={email} onChange={(e) => { setemail(e.target.value) }} placeholder="John@gmail.com" type="text" className="form-control" />
                                    Mobile:<input value={mobile} onChange={(e) => { setmobile(e.target.value) }} placeholder="+1 897 254 2563" type="number" maxLength={10} className="form-control" />
                                    Address:<textarea value={address} onChange={(e) => { setaddress(e.target.value) }} col="10" className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-3">
                                    <select value={values} className="form-select px-5" onChange={handleForm}>
                                        <option selected>Choose our Plan</option>
                                        {plan.map((item) => {
                                            return (
                                                <option value={item.plan}>{item.planname}      <span>${item.actualprice}</span> </option>
                                            )
                                        })}
                                    </select>
                                    {/* {values} */}
                                </div>
                            </div>
                            <button type="button" style={{ backgroundColor: "#116AAC" }} onClick={apptAdd} className="mt-5 p-3  rounded-pill btn btn-outline-light w-50 d-block mx-auto">Book </button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}