import { useEffect, useState } from "react"
import Apiservices from "../API/Apiservices"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { RingLoader } from "react-spinners"
export default function Pricesetting(){
const[plan ,setplan]=useState([]) 
const[load,setload]=useState(false)   
 useEffect(()=>{
    Apiservices.planAll().then((res)=>{
        setplan(res.data.data)
    }).catch((err)=>{
        toast.error("Error"+err)
    })
 },[load])
 
 const deletePlan=(id)=>{
    setload(true)
    let data={
     _id:id   
    }
    Apiservices.planDelete(data).then((res)=>{
        if(res.data.success===false){
                setload(false)
                toast.error(res.data.message)
        }else{
                setload(false)
                toast.success(res.data.message)
            }
    }).catch((err)=>{
        setload(false)
        toast.error("error"+err)
    })
    
}
const customStyle={
    position:"Absolute",
    top:"50%",
    left:"48%",
    zIndex:1
}
    return(
        <>
           <div className="container">
                <div className="row">
                    <p className="display-1 fw-bold text-center">Price Setting</p>    
                </div> 
                <div className="row">
                    <div className=" table-responsive">
                    <table className="table table-bordered">
                        <thead className="table-dark">
                        <tr>
                            <th>Sno</th>
                            <th>PlanName</th>
                            <th>DeletedPrice</th>
                            <th>ActualPrice</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {plan.map((item,index)=>{
                                return(
                                    <tr key={index} >
                                    <td>{index+1}</td>
                                    <td>{item.planname}</td>
                                    <td>{item.deletedprice}</td>
                                    <th>{item.actualprice}</th>
                                    <td><button onClick={()=>{deletePlan(item._id)}} className="btn btn-danger d-block mx-auto  ">Delete</button></td>
                                    <td><Link to={`/admin/price/update/${item._id}`}  className="btn btn-primary d-block mx-auto  ">Edit</Link></td>
                                    </tr>
                                )    
                            })} 
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg">
                        <Link to={"/admin/addplan"}> <button  className="btn btn-success d-block mx-auto  mt-5 btn-lg py-2 ">Add Plan</button></Link>
                    </div>
                </div>
           </div>      
        </>
    )
}