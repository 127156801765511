import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export default function Headers() {
    const nav = useNavigate()
    let token = sessionStorage.getItem('token')
    const logout = () => {
        sessionStorage.clear()
        toast.success("succcessfully logout")
        setTimeout(() => {
            nav("/login")
        }, 3000)
    }
    return (
        <>
            {/* Top Bar Start */}
            <div className="top-bar">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-12 sm-12">
                            <div className="logo">
                                <Link style={{textDecoration:"none"}} to="/">
                                    <h1>CarPort<span>.asd</span></h1>
                                    {/* <img src="img/logo.jpg" alt="Logo"> */}
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 d-none d-lg-block">
                            <div className="row">
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon">
                                            <i className="far fa-clock"></i>
                                        </div>
                                        <div className="top-bar-text">
                                            <h3>Opening Hour</h3>
                                            <p>Mon-Sun, 9:00 am - 9:00 pm</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                <Link style={{textDecoration:"none",color:"black"}} to={""}>
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon animate__animated animate__headShake animate__slow animate__repeat-3 animate__delay-1s ">
                                            <i className="fa fa-phone-alt "></i>
                                        </div>
                                         <div  className="top-bar-text">
                                            <h3  >Call Us</h3>
                                            <p>+1 (705) 662-2001</p>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-icon">
                                            <i className="far fa-envelope"></i>
                                        </div>
                                        <div className="top-bar-text">
                                            <h3>Email Us</h3>
                                            <p>Carport.asd@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Top Bar End */}

            {/* Nav Bar Start */}
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand" to="#"><img height={"100px"} src="/img/logo.png"/></a>
                    <button className="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon "></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="xyz navbar-nav me-auto mx-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active"  to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/services">Service</Link>
                            </li>
                            <li class="nav-item">
                                <Link className="nav-link" to="/price">Price</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/appointment">Appointment</Link>
                            </li>
                        </ul>
                        <form class="d-flex" role="search">
                            {!token ? <Link className="btn btn-custom" to="/login">Log in</Link> : <Link className="btn btn-custom" onClick={logout} to="/login" >Log out</Link>}
                            {!token ? <Link className="btn btn-custom mx-1" to="/register" >Registration</Link> : ""}
                        </form>
                    </div>
                </div>
            </nav>


        </>
    )
}