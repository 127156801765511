import axios from "axios"
import qs from "qs"
const BASE_URL="https://backend.carportasd.com/"   
class ApiServices{
    enquiry(data){
        let obj={
            Authorization:sessionStorage.getItem('token')
        }
        return axios.post(BASE_URL+"api/enquiry/add", qs.stringify(data),{headers:obj})
    }
    enquiryall(data){
        return axios.post(BASE_URL+"admin/enquiry/all",qs.stringify(data))    
    }

    enquirydelete(data){
        let obj={
            Authorization:sessionStorage.getItem('token')
        }
        return axios.post(BASE_URL+"admin/enquiry/delete",data,{headers:obj})
    }
    //login
    login(data){
        return axios.post(BASE_URL+"api/login",qs.stringify(data))
    }

    //forgot password

    forgotpwd(data){
        return axios.post(BASE_URL+"api/forgotpassword",qs.stringify(data))
    }
    //appointment
    appt(data){
        return axios.post(BASE_URL+"admin/appointment/all",qs.stringify(data))
    }
    apptDel(data){
        let head={
            Authorization:sessionStorage.getItem('token')
        }
        return axios.post(BASE_URL+"admin/appointment/delete",data,{headers:head})
    }
    apptAdd(data){
            let obj={
                Authorization:sessionStorage.getItem('token')
            }
            return axios.post(BASE_URL+"api/appointment/add",qs.stringify(data),{headers:obj})
    }
    //user
    useradd(data){
        return axios.post(BASE_URL+'api/user/add',qs.stringify(data))
    }
    userall(){
        return axios.post(BASE_URL+'admin/user/all')
    }
    userDelete(data){
        let obj={
            Authorization:sessionStorage.getItem('token')
        }
        return axios.post(BASE_URL+"admin/user/delete",data,{headers:obj})
    }
    userSingle(data){
        let obj={
            Authorization:sessionStorage.getItem('token')
        }
        return axios.post(BASE_URL+"api/user/single",data,{headers:obj})
    }

    //dashboard
    dashboard(){
        return axios.post(BASE_URL+"admin/dashboard")
    }
    //plans
    planAll(){
        return axios.post(BASE_URL+"admin/plan/all")
    }
    planAdd(data){
        let obj={
            Authorization:sessionStorage.getItem("token")
        }
        return axios.post(BASE_URL+"admin/plan/add",data,{headers:obj})
    }
    planSingle(data){
        let obj={
            Authorization:sessionStorage.getItem('token')
        }
        return axios.post(BASE_URL+"admin/plan/single",data,{headers:obj})
    }
    planUpdate(data){
        let obj={
            Authorization:sessionStorage.getItem('token')
        }
        return axios.put(BASE_URL+"admin/plan/update",data,{headers:obj})
    }
    planDelete(data){
        let obj={
            Authorization:sessionStorage.getItem("token")
        }
        axios.post(BASE_URL+"admin/plan/delete",data,{headers:obj})
    }
}

export default new ApiServices