import { useEffect } from "react"
import { useState } from "react"
import Apiservices from "../API/Apiservices"
import { toast } from "react-toastify"

export default function Adminappointments(){
    const [appt,setappt]=useState([]) 
    const[load ,setload]=useState(false)  
    useEffect(()=>{
         Apiservices.appt().then((item)=>{
                setappt(item.data.data)
         })   
    },[load])
    
    const deleteAppt=(id)=>{
        console.log(id)
        setload(true)
        let data={
            _id:id
        }
        Apiservices.apptDel(data).then((item)=>{
                if(item.data.success===false){
                    setload(false)
                    toast.error(item.data.message)
                }else{
                    setload(false)
                    toast.success(item.data.message)
                }
        }).catch((err)=>{
                    toast.error("Error"+err)
        })
    }
    return(
        <>
        <div className="container text-center p-0">
            <div className="row">
                <p className="text-center display-2 fw-bold">Appointments</p>
            </div> 
            <div className="row">
               <div className="col-lg col-md-12">
                    <div className="table-responsive">
                    <table className="table table-bordered ">
                        <thead className="table-dark">
                            <tr>
                                <th>S no.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Address</th>
                                <th>Plan</th>
                                <th>Date</th>
                                <th>Done</th>
                            </tr>
                        </thead>
                           {appt.map((item ,index)=>{
                                return(
                                    <>
                                      <tbody>
                                          <tr key={index}>
                                            <td>{index+1}</td>  
                                            <td>{item.name}</td>  
                                            <td>{item.email}</td>  
                                            <td>{item.mobile}</td>  
                                            <td>{item.address}</td>  
                                            <td>{item.plan}</td>  
                                            <td>{item.createdAt}</td>
                                            <td><button onClick={()=>{deleteAppt(item._id)}} className="btn btn-success" >Done</button></td>  
                                          </tr>  
                                      </tbody>  
                                    </>
                                )
                           })} 
                    </table>
                    </div>
                </div> 
            </div> 
        </div>    
        </>
    )
}