import { useState } from "react"
import Apiservices from "../API/Apiservices"
import { toast } from "react-toastify"
import { RingLoader } from "react-spinners"
export default function AddPlan(){
const[load,setload]=useState(false)
const [name,setname]=useState("")
const [actualprice,setactualprice]=useState("")
const [deletedprice,setdeleteprice]=useState("")    
const addplan=(e)=>{
    e.preventDefault()
    setload(true)
    let data={
        planname:name,
        deletedprice:deletedprice,
        actualprice:actualprice
    }
    Apiservices.planAdd(data).then((res)=>{
        if(res.data.success===false){
            setTimeout(()=>{
                setload(false)
                toast.error(res.data.message)
            },3000)
        }else{
            setTimeout(()=>{
                setload(false)
                toast.success(res.data.message)
            },3000)
        }
    }).catch((err)=>{
        toast.error("error:"+err)
    })
}

const customStyle={
    position:"Absolute",
    left:"48%",
    top:"50%",
    zIndex:1
}
return(
        <>
        <RingLoader size={100} color="lime" loading={load} cssOverride={customStyle}/>
        <div className={load===true?"disable-screen":""}>
           <div className="container">
               <div className="row">
                    <p className="text-center display-1 fw-bold">Add plan</p>
                    <form>
                    <div className="offset-md-3 col-lg-6 col-md-3 mb-3">
                        <label for="exampleInputEmail1" className="form-label">Plan Name</label>
                        <input value={name} onChange={(e)=>{setname(e.target.value)}} type="text" className="form-control" />
                    </div>
                    <div className="offset-md-3 col-lg-6 col-md-3 mb-3">
                        <label className="form-label">DeletedPrice</label>
                        <input value={deletedprice} onChange={((e)=>{setdeleteprice(e.target.value)})} type="text" className="form-control" />
                    </div>
                    <div className="offset-md-3 col-lg-6 col-md-3 mb-3">
                        <label className="form-label">ActualPrice</label>
                        <input value={actualprice} onChange={(e)=>{setactualprice(e.target.value)}} type="text" className="form-control" />
                    </div>
                    <div className="row">
                        <div className="offset-md-3 col-lg-6 col-md-3">
                            <button onClick={addplan} type="submit" className="btn btn-primary d-block mx-auto w-50">Submit</button>
                        </div>
                    </div>
                </form>
                </div> 
            </div> 
        </div>    
        </>
    )
}