import { useState } from "react"
import Apiservices from "../API/Apiservices"
import { ToastContainer, toast } from "react-toastify"
import { RingLoader } from "react-spinners"


export default function Forgetpwd() {
    const [load, setload] = useState(false)
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const forgotpassFunc = (e) => {
        e.preventDefault()
        setload(true)
        let data = {
            email: email,
            newpwd: password
        }
        Apiservices.forgotpwd(data).then((res)=>{
            console.log(res)
            if(res.data.success===false){
                setload(false)
                toast.error(res.data.message)
            }else{
                setTimeout(()=>{
                    setload(false)
                    toast.success(res.data.message)
                },3000)
            }
        }).catch(()=>{
            toast.error("something went wrong")
        })
    }
    const customStyle = {
        position: "Absolute",
        left: "45%",
        top: "48%",
        zIndex: 1
    }
    return (
        <>
            <div className={load === true ? "disable-screen" : ""}>
                <RingLoader loading={load} size={100} color="lime" cssOverride={customStyle} />
                <div style={{ height: "60vh" }} className="container ">
                    <div className="row pt-3 d-flex justify-content-center ">
                        <div className="col-md-4 border">
                            <form>
                                <img className="mb-4 pt-1 " src="/img/logo.png" alt="" width="72" height="57" />
                                <h1 className="h3 mb-3 fw-normal text-cen">Forgot Password</h1>

                                <div className="form-floating mt-3">
                                    <input value={email} onChange={(e) => setemail(e.target.value)} type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                    <label for="floatingInput">Email address</label>
                                </div>
                                <div className="form-floating mt-3">
                                    <input value={password} onChange={(e) => { setpassword(e.target.value) }} type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                                    <label for="floatingPassword">New Password</label>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <button onClick={forgotpassFunc} className="btn btn-primary  mx-3 w-25 mt-5 mb-5" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}