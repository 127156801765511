import { Link } from "react-router-dom"
export default function About(){
const customStyle={
  backgroundImage: "url('/img/carousel-1.jpg')",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundSize:"cover",
  backgroundPosition:"center",
  height:"60vh"
}
  return(
        <>
        {/* Page Header Start */} 
        <div style={customStyle} className="page-header">
            <div  className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>About Us</h2>
                    </div>
                    <div className="col-12">
                        <Link to="/">Home</Link>
                        <Link to="/about">About</Link>
                    </div>
                </div>
            </div>
        </div>
        {/* Page Header End */}
        {/* <!-- Gallery --> */}

 <div className="row ">
    <div className="col-lg-3 col-md-3 ">
      <p className="fs-3 text-center" >BEFORE </p>
      <img className="img-fluid p-3" src="/img/unclean5.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >AFTER </p>
      <img className="img-fluid p-3" src="/img/clean5.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >BEFORE </p>
      <img className="img-fluid p-3" src="/img/unclean6.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >AFTER </p>
      <img className="img-fluid p-3" src="/img/clean6.jpg"/>
    </div>
</div> 
<div className="row ">
    <div className="col-lg-3 col-md-3 ">
      <p className="fs-3 text-center" >BEFORE </p>
      <img className="img-fluid p-3" src="/img/unclean7.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >AFTER </p>
      <img className="img-fluid p-3" src="/img/clean7.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >BEFORE </p>
      <img className="img-fluid p-3" src="/img/unclean8.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >AFTER </p>
      <img className="img-fluid p-3" src="/img/clean8.jpg"/>
    </div>
</div>
<div className="row ">
    <div className="col-lg-3 col-md-3 ">
      <p className="fs-3 text-center" >BEFORE </p>
      <img className="img-fluid p-3" src="/img/unclean9.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >AFTER </p>
      <img className="img-fluid p-3" src="/img/clean9.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >BEFORE </p>
      <img className="img-fluid p-3" src="/img/unclean10.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >AFTER </p>
      <img className="img-fluid p-3" src="/img/clean10.jpg"/>
    </div>
</div>
<div className="row ">
    <div className="col-lg-3 col-md-3 ">
      <p className="fs-3 text-center" >BEFORE </p>
      <img className="img-fluid p-3" src="/img/unclean11.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >AFTER </p>
      <img className="img-fluid p-3" src="/img/clean11.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >BEFORE </p>
      <img className="img-fluid p-3" src="/img/unclean12.jpg"/>
    </div>
    <div className="col-lg-3 col-md-3">
      <p className="fs-3 text-center" >AFTER </p>
      <img className="img-fluid p-3" src="/img/clean12.jpg"/>
    </div>
</div>
{/* <!-- Gallery --> */}


 
        </>
    )
}