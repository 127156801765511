import { Outlet } from "react-router-dom";
import Headers from "./Headers";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css"
import "animate.css"

export default function Master(){
    return(
        <>  
               <Headers/>
               <Outlet/>
               <Footer/> 
         
        </>
    )
}