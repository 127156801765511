import { useEffect, useState } from "react"
import Apiservices from "../API/Apiservices"
import { toast } from "react-toastify"
export default function Admincontact(){  
const [allEnquiry,setallEnquiry]=useState([])
const [load ,setload]=useState(false)  
  useEffect(()=>{
      Apiservices.enquiryall().then((item)=>{  
            setallEnquiry(item.data.data)
      }).catch((err)=>{
          toast.error('error:'+err)
      })
  },[load]) 
  
const Done=(id)=>{
    console.log(id)
    setload(true)
    let data={
      _id:id
    }
    Apiservices.enquirydelete(data).then((res)=>{
      if(res.data.success===false){
        setload(false)
        toast.error(res.data.message)
      }else{
        setload(false)
        toast.success(res.data.message)
      }
    }).catch((err)=>{
      setload(false)
      toast.error("Error"+err)
    })
}

  return(
        <>
          <div className="container text-center">
            <div className="row"> 
                <p className="display-2 text-center fw-bold">Enquiry</p>
            </div>
            <div className="row">
                <div className="table table-responsive{md}">
                  <table className="table table-bordered ">
                        <thead className="table-dark">
                          <tr>
                            <th>S no.</th>  
                            <th>Date</th>
                            <th>Name</th>  
                            <th>Email</th>  
                            <th>Phone</th>  
                            <th>Subject</th>  
                            <th>Message</th>  
                            <th>Reply</th>  
                          </tr>  
                        </thead>
                        <tbody> 
                        {allEnquiry.map((values, index)=>{
                          return(
                            <>
                               <tr key={index}>
                                  <td>{index+1}</td>
                                   <td>{values.createdAt}</td>                                         
                                   <td>{values.name}</td>                                         
                                   <td>{values.email}</td>                                         
                                   <td>{values.phone}</td>                                         
                                   <td>{values.subject}</td>                                         
                                   <td>{values.message}</td> 
                                   <td>
                                    <button onClick={()=>{Done(values._id)}} className="btn btn-success">Done</button> 
                                   </td>                                        
                                </tr>
                            </>
                          )
                        })}   
                        </tbody>                
                  </table>
                </div>
            </div>  
          </div>  
        </>
    )
}