export default function Pagenotfound(){
    return(
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <img src="https://th.bing.com/th/id/OIP.jCw3QXMJhPPetoiDyIVDPAHaD8?w=355&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7" height={"100%"} width={"100%"} alt="404" />
                </div>
            </div>
        </>
    )
}