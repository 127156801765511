
import { useState } from "react"
import Apiservices from "../API/Apiservices"
import { ToastContainer, toast } from "react-toastify"
import { RingLoader } from "react-spinners"

export default function Contact(){
   const[load,setload]=useState(false)
   const[name ,setname]=useState('')
   const[email,setemail]=useState('')
   const[phone, setphone]=useState("")
   const[title,settitle]=useState('')
   const[Description, setdescription]=useState('')
   const coverStyle={
    backgroundImage: "url('/img/message.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize:"cover",
  backgroundPosition:"center",
    height:"60vh"
    }  

   const submitform=(e)=>{
        e.preventDefault()
    let data={
        name:name,
        email:email,
        phone:phone,
        subject:title,
        message:Description
    }      
        setload(true)  
        Apiservices.enquiry(data).then(
            (res)=>{
               console.log(res)
               if(res.data.success==false){
                    setTimeout(()=>{
                    setload(false)  
                    toast.error("Please Login")
                },3000)  
               }else{
                    setTimeout(()=>{
                    setload(false)
                    toast.success(res.data.message)
                },3000)}           
            }
        ).catch((err)=>{
            toast.error('something went wrong'+err)
        })
   }
 const customStyle={
    position:"Absolute",
    left:"48%",
    top:"105%",
    zIndex:1
 }  
    return(
        <>
        <div className={load==true?"disable-screen":""}>
        <RingLoader loading={load} size={100} color="lime" cssOverride={customStyle}/>
        {/* Page Header Start */}
        <div style={coverStyle} className="page-header">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Contact Us</h2>
                    </div>
                    <div className="col-12">
                        <a href="">Home</a>
                        <a href="">Contact</a>
                    </div>
                </div>
            </div>
        </div>
        {/* Page Header End */}
        
        
        {/* Contact Start */}
        <div className="contact">
            <div className="container">
                <div className="section-header text-center">
                    <p>Get In Touch</p>
                    <h2>Contact for any query</h2>
                </div>
                <div className="row">
                    <div className="col-md-4 animate__animated animate__fadeIn animate__slow animate__repeat-1 animate__delay-1s">
                        <div className="contact-info">
                            <h2>Quick Contact Info</h2>
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="far fa-clock"></i>
                                </div>
                                <div className="contact-info-text">
                                    <h3>Opening Hour</h3>
                                    <p>Mon - Sun, 9:00 am - 9:00 pm</p>
                                </div>
                            </div>
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fa fa-phone-alt"></i>
                                </div>
                                <div className="contact-info-text">
                                    <h3>Call Us</h3>
                                    <p>+1(705)622 2614</p>
                                    <p>+1(705) 622 2001</p>
                                </div>
                            </div>
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="far fa-envelope"></i>
                                </div>
                                <div className="contact-info-text">
                                    <h3>Email Us</h3>
                                    <p>Carport.asd@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 animate__animated animate__fadeIn animate__slow animate__repeat-1 animate__delay-2s">
                        <div className="contact-form">
                            <div id="success"></div>
                            <form name="sentMessage" id="contactForm" >
                                <div className="control-group">
                                    <input value={name} onChange={(e)=>{setname(e.target.value)}} type="text" className="form-control" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="email" value={email} onChange={(e)=>{setemail(e.target.value)}} className="form-control" id="email" placeholder="Your Email" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="text" value={title} onChange={(e)=>{settitle(e.target.value)}} className="form-control" id="subject" placeholder="Subject" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input value={phone} onChange={(e)=>{setphone(e.target.value)}} type="text" className="form-control" id="phone" placeholder="phone" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <textarea value={Description } onChange={(e)=>{setdescription(e.target.value)}} className="form-control" id="message" placeholder="Message" ></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div>
                                    <button  onClick={submitform} className="btn btn-custom d-block mx-auto" type="submit" id="sendMessageButton">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2694.3989839581577!2d-79.6614736!3d47.52109480000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d267b59dc87f90b%3A0x6c11e144087ae362!2sCarport%20Auto%20Sale%20And%20Detailing!5e0!3m2!1sen!2sin!4v1706872960352!5m2!1sen!2sin"  style={{border:0, frameborder:"0", allowfullscreen:""}}  aria-hidden="false" tabIndex="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <ToastContainer/>
     {/* Contact End */}
        </>
    )
}