import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Apiservices from "../API/Apiservices";
import { toast } from "react-toastify";
export default function Price(){
    const customStyle={
        backgroundImage: "url('/img/carwash.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize:"cover",
        backgroundPosition:"center",
        height:"60vh"
}   
const token=sessionStorage.getItem("token")
const [PreDelPrice,setPreDelPrice]=useState(0)
const [FirstDelPrice,setFirstDelPrice]=useState(0)
const [PreActualPrice,setPreActualPrice]=useState(0)
const [FirstActualPrice,setFirstActualPrice]=useState(0)
   
useEffect(()=>{
    Apiservices.planAll().then((res)=>{
        console.log(res)
        setFirstDelPrice(res.data.data[0].deletedprice)
        setFirstActualPrice(res.data.data[0].actualprice)
        setPreDelPrice(res.data.data[1].deletedprice)
        setPreActualPrice(res.data.data[1].actualprice)
    }).catch((err)=>{
        toast.error("error"+err)
    })
},[])   
if(!token){
    toast.error("please login")
}  
    return(
        <>
        {/* <!-- Page Header Start --> */}
        <div style={customStyle} className="page-header img-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Washing Plan</h2>
                    </div>
                    <div className="col-12">
                        <Link to="/">Home</Link>
                        <Link to="/price">Price</Link>
                    </div>
                </div>
            </div>
        </div>
       {/* <!-- Page Header End -->*/}
         {/* Price Start */}
        <div className="price">
            <div className="container">
                <div className="section-header text-center">
                    <p>Washing Plan</p>
                    <h2>Choose Your Plan</h2>
                </div>
                <div className="row">
                    <div className="col-md-6 animate__animated animate__zoomIn animate__slow animate__repeat-1 animate__delay-2s">
                        <div className="price-item ">
                            <div className="price-header">
                                <h3>Premium Class</h3>
                                <h2 style={{color:'red'}}>
                                    <span>$</span><strong><sup><del>{PreDelPrice}</del></sup>{PreActualPrice}</strong><span>.99</span>
                                </h2>
                            </div>
                            <div className="price-body">
                            <ul >
                                    <li><h2>Interior Cleaning</h2></li>
                                    <li><i className="far fa-check-circle"></i>
                                     Vaccum and Dusting</li>
                                    <li><i className="far fa-check-circle"></i>Interior Wet Cleaning</li>
                                    <li><i className="far fa-check-circle"></i>Window Wiping</li>
                                    <li><i className="far fa-check-circle"></i>Complete Interior Manual Wash
                                        <li ><i className="far fa-check-circle"></i>Interior Windows and Mirrors </li>
                                        <li><i className="far fa-check-circle"></i>Center Console and Cup Holders</li>
                                        <li ><i className="far fa-times-circle"></i>Leather Conditioning </li>
                                    </li>
                                    
                                </ul>
                                <ul >
                                    <li><h2>Exterior Cleaning</h2></li>
                                    <li><i className="far fa-check-circle"></i>Rims and Wheels detailed</li>
                                    <li><i className="far fa-check-circle"></i>Exterior Window's Cleaning</li>
                                    <li><i className="far fa-check-circle"></i>Odour Treatment</li>
                                    <li><i className="far fa-times-circle"></i>Engine Bay Cleaning</li>
                                    <li><i className="far fa-times-circle"></i>Creamic Spray Shiner</li>
                                </ul>
                            </div>
                            <div className="price-footer">
                                <Link  className="btn btn-custom"to={"/appointment"} >Book Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 animate__animated animate__zoomIn animate__slow animate__repeat-1 animate__delay-3s">
                        <div className="price-item">
                            <div className="price-header">
                                <h3>First Class</h3>
                                <h2 style={{color:'purple'}}>
                                    <span>$</span> <strong><sup><del>{FirstDelPrice}</del></sup>{FirstActualPrice}</strong><span>.99</span>
                                </h2>
                            </div>
                            <div className="price-body">
                                <ul >
                                    <li><h2>Interior Cleaning</h2></li>
                                    <li><i className="far fa-check-circle"></i>
                                     Vaccum and Dusting</li>
                                    <li><i className="far fa-check-circle"></i>Interior Wet Cleaning</li>
                                    <li><i className="far fa-check-circle"></i>Window Wiping</li>
                                    <li><i className="far fa-check-circle"></i>Complete Interior Manual Wash
                                        <li ><i className="far fa-check-circle"></i>Interior Windows and Mirrors </li>
                                        <li ><i className="far fa-check-circle"></i>Center Console and Cup Holders </li>
                                        <li ><i className="far fa-check-circle"></i>Leather Conditioning </li>
                                    </li>
                                    
                                </ul>
                                <ul >
                                    <li><h2>Exterior Cleaning</h2></li>
                                    <li><i className="far fa-check-circle"></i>Rims and Wheels detailed</li>
                                    <li><i className="far fa-check-circle"></i>Exterior Window's Cleaning</li>
                                    <li><i className="far fa-check-circle"></i>Odour Treatment</li>
                                    <li><i className="far fa-check-circle"></i>Engine Bay Cleaning</li>
                                    <li><i className="far fa-check-circle"></i>Creamic Spray Shiner</li>
                                </ul>
                            </div>
                            <div className="price-footer">
                                <Link className="btn btn-custom" to={"/appointment"} >Book Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Price End */}
        
           
        </>
    )
}