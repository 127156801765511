import { useEffect, useState } from "react"
import Apiservices from "../API/Apiservices"
import { toast } from "react-toastify"
import { RingLoader } from "react-spinners"
import { Link } from "react-router-dom"


export default function Alluser(){
const[user,setuser]=useState([])
const[load,setload]=useState(false)
useEffect(()=>{
    Apiservices.userall().then((res)=>{
        setuser(res.data.data)
    }).catch((err)=>{
        toast.error("Error"+err)
    })
},[load])

const deleteUser=(id)=>{
    setload(true)
    console.log(id)
    let data={
     _id:id   
    }
    Apiservices.userDelete(data).then((res)=>{
        if(res.data.success===false){
            console.log(res)
            setTimeout(()=>{
                setload(false)
                toast.error(res.data.message)
            },3000)
        }else{
            setTimeout(()=>{
                setload(false)
                toast.success(res.data.message)
            },3000)
        }
    }).catch((err)=>{
        setload(false)
        toast.error("error"+err)
    })
    
}

const customStyle={
    position:"Absolute",
    top:"50%",
    left:"48%",
    zIndex:1
}
    return(
        <>
        <RingLoader size={100} color="lime" loading={load} cssOverride={customStyle}/>
        <div classNameName={load===true?"disable-screen":""}>
            <div className="row">
                <div className="display-1 fw-bold  text-center">Customer List</div>
            </div>
            <div className="row">
                <div className="col">
                <div className="table-responsive">    
                <table className="table table-bordered">
                    <thead  className="table-dark">
                        <tr>
                            <td>S no.</td>    
                            <td>Name</td>    
                            <td>Email</td>    
                            <td>Contact</td>    
                            <td>Address</td> 
                            <td></td>   
                            <td></td>   
                        </tr>    
                    </thead>
                    {user.map((item,index)=>{
                        return(
                            <tbody className="">
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.contact}</td>
                                    <td>{item.address}</td>
                                    <td><button onClick={()=>{deleteUser(item._id)}} className="btn d-block mx-auto btn-danger my-2">Delete</button></td>
                                    <td><button className="btn d-block mx-auto btn-primary my-2">Edit</button></td>
                                </tr>
                            </tbody>
                        )
                    })}    
                </table>
                </div>    
            </div>  
            </div>
          </div>  
        </>
    )
}