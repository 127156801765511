import { useState } from "react";
import { RingLoader } from "react-spinners";
import Apiservices from "../API/Apiservices";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'

export default function Register() {
    const [name, setname] = useState('')
    const [address, setaddress] = useState('')
    const [email, setemail] = useState('')
    const [contact, setcontact] = useState('')
    const [password, setpassword] = useState('')
    const [load, setload] = useState(false)
    const nav = useNavigate()

    const reg = (e) => {
        e.preventDefault()
        setload(true)
        let data = {
            name: name,
            email: email,
            password: password,
            contact: contact,
            address: address
        }
        Apiservices.useradd(data).then((res) => {
            toast.success(res.data.message)
            setTimeout(() => {
                toast.success('please Login')
                setload(false)
                nav("/login")
            }, 3000);
        }).catch((err) => {
            setTimeout(() => {
                toast.error("Error" + err)
                setload(false)
            }, 3000);
        })
    }
    const customStyle = {
        position: "Absolute",
        left: "48%",
        top: "50%",
        zIndex: 1
    }
    return (
        <>
            <RingLoader size={100} loading={load} color="lime" cssOverride={customStyle} />
            <div className={load === true ? "disable-screen" : ""}>
                <div className="container">
                    <div className="row  ">
                        <div className="col-lg-6 col-md-6 "><img  className="img-fluid my-5 animate__animated animate__fadeInUp animate__slow animate__repeat-1 animate__delay-1s " src="/img/carousel-1.jpg" width={"100%"} alt="register" /></div>
                        <div className="col-lg-6 col-md-6 animate__animated animate__fadeInUp animate__slow animate__repeat-1 animate__delay-2s">
                            <div className="row">
                                <div className="col-lg"><p className="fs-1 fw-bold pt-3 text-center">Registration</p></div>
                            </div>
                            <div className="row">
                                <input value={name} onChange={(e) => { setname(e.target.value) }} placeholder="Enter your Name" className="form-control  mt-3" />
                                <input value={email} onChange={(e) => { setemail(e.target.value) }} placeholder=" Email" className="form-control mt-3" />
                                <input value={contact} onChange={(e) => { setcontact(e.target.value) }} placeholder=" mobile" className="form-control mt-3" />
                                <input value={address} onChange={(e) => { setaddress(e.target.value) }} placeholder="address" className="form-control mt-3" />
                                <input value={password} onChange={(e) => { setpassword(e.target.value) }} type="password" placeholder="password" className="form-control mt-3" />
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className=" offset-md-1 col-md-6 ">
                                    <button className="btn btn-custom  mt-3 " onClick={reg}> Register </button>
                                    <Link to="/login"> <button className="btn btn-custom mx-3  mt-3 "> Sign in</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}