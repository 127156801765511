import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import {  ToastContainer, toast } from "react-toastify";
export default function AdminHeader(){
    const [load,setload]=useState(false)
    const nav=useNavigate()
    const logout=()=>{  
        toast.error("logout successfully")
        sessionStorage.clear("user")
        setTimeout(()=>{
            nav('/')
        },3000)      
}
const customStyle={
    position:"Absolute",
    left:"48%",
    top:"50%",
    zIndex:1
}
    return(
        <>
        <div className={load==true?"disable-screen":""}>
        <RingLoader size={100} color="lime" loading={load} cssOverride={customStyle}/>
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid">
                    <a class="navbar-brand" to="#"><h1>Admin Carport.asd</h1></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul class="xyz navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <Link class="nav-link active"  to="/admin">DashBoard</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/admin/adminappointments">Appointments</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/admin/pricesetting">Price Setting</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/admin/admincontact">Contact</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/admin/alluser">All user</Link>
                            </li>
                        </ul>
                        <form class="d-flex" role="search">
                            <Link onClick={logout} className="btn btn-custom" to={"/login"}>Logout</Link>
                        </form>
                    </div>
                </div>
            </nav>
            </div>
    <ToastContainer/> 
        </>
    )
}