import { Navigate, Outlet } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import "bootstrap/dist/css/bootstrap.min.css"

export default function AdminMaster(){
const token=sessionStorage.getItem("token")
const userType=sessionStorage.getItem("userType")
 if(  !token ||userType==2 ){
    toast.error('Restricted')
    return <Navigate to="/login" />
 }
  return(
        <>
        <div className="container-fluid">
          <AdminHeader/>
          <Outlet/>  
        </div>
          <ToastContainer/>
        </>
    )
}