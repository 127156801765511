import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
export default function Hero() {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  return (
    <>
       <AutoplaySlider   play={true}  cancelOnInteraction={false} interval={6000}  style={{zIndex:-1}}>
    <div  data-src="/img/car2.png" />
    <div   data-src="/img/car.png" />
    <div data-src="/img/car4.png" />
  </AutoplaySlider>
    </>
  )
}