import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Apiservices from "../API/Apiservices"
import { toast } from "react-toastify"
import CountUp from 'react-countup'
import Hero from "../components/hero"
import Modalxox from "../components/modal"
export default function Home() {
    const [firstclassName, setfirstclass] = useState(0)
    const [PreclassName, setPreclass] = useState(0)
    useEffect(() => {
        Apiservices.planAll().then((res) => {
            console.log(res.data.data)
            setPreclass(res.data.data[1].actualprice)
            setfirstclass(res.data.data[0].actualprice)
        }).catch((err) => {
            toast.error(err)
        })
    }, [])

    
    return (
        <>
            <Modalxox/>  
            <Hero/>
            {/* About Start */}
            <div className="about pt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="/img/aboutcar.jpeg" alt="Image" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-header text-left">
                                <p>About Us</p>
                                <h2>car washing and detailing</h2>
                            </div>
                            <div className="about-content">
                                <p>
                                    We wash your car at your Home
                                </p>
                                <ul>
                                    <li><i className="far fa-check-circle"></i>Seats washing</li>
                                    <li><i className="far fa-check-circle"></i>Vacuum cleaning</li>
                                    <li><i className="far fa-check-circle"></i>Interior wet cleaning</li>
                                    <li><i className="far fa-check-circle"></i>Window wiping</li>
                                </ul>
                                <Link className="btn btn-custom" to="/about">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}


            {/* Service Start */}
            <div className="service">
                <div className="container">
                    <div className="section-header text-center">
                        <p>What We Do?</p>
                        <h2>Premium Washing Services</h2>
                    </div>
                    <div className="row animate__animated animate__fadeIn animate__slow animate__repeat-1 animate__delay-1s">
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-wash-1"></i>
                                <h3>Exterior Washing</h3>
                                <p>"Wash away the dirt and grime for a shiny exterior!"</p>
                                <p></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-wash"></i>
                                <h3>Interior Washing</h3>
                                <p>“We don’t cut corners, we clean them.”</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-vacuum-cleaner"></i>
                                <h3>Vacuum Cleaning</h3>
                                <p>“Swipe Away the Dust with a Vacuum”</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-seat"></i>
                                <h3>Seats Washing</h3>
                                <p>“We leave every space cleaner than we found it.”</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-service"></i>
                                <h3>Window Wiping</h3>
                                <p>"No window is too dirty for us to clean!"</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-service-2"></i>
                                <h3>Wet Cleaning</h3>
                                <p>Get the dust and dirt clean in a few seconds.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-car-wash"></i>
                                <h3>Odour Treatment</h3>
                                <p>You Love Your Car, We Love Cleaning It.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
                                <i className="flaticon-brush-1"></i>
                                <h3>Tyre Dressing & Cleaning</h3>
                                <p>Fast and friendly car wash</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service End */}


            {/* Facts Start */}
            <div className="facts" data-parallax="scroll" data-image-src="img/facts.jpg">
                <div className="container d-flex justify-content-around ">
                    <div className="row ">
                        <div className="col-lg-4 col-md-6">
                            <div className="facts-item">
                                <i className="fa fa-user"></i>
                                <div className="facts-text">
                                    <h3><CountUp end={50} delay={2} /></h3>
                                    <p>Reviews</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="facts-item">
                                <i className="fa fa-users"></i>
                                <div className="facts-text">
                                    <h3 ><CountUp end={50} delay={3} /></h3>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="facts-item">
                                <i className="fa fa-check"></i>
                                <div className="facts-text">
                                    <h3> <CountUp end={50} delay={4} /></h3>
                                    <p>Projects Completed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Facts End */}


            {/* Price Start */}
            <div className="price">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Washing Plan</p>
                        <h2>Choose Your Plan</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6 animate__animated animate__zoomIn animate__slower animate__repeat-1 animate__delay-2s">
                            <div className="price-item featured-item">
                                <div className="price-header">
                                    <h3>Premium class</h3>
                                    <h2 style={{ color: "red" }}><span>$</span><strong>{PreclassName}</strong><span>.99</span></h2>
                                </div>
                                <div className="price-body">
                                    <ul>
                                        <li><i className="far fa-check-circle"></i>Seats Washing</li>
                                        <li><i className="far fa-check-circle"></i>Vacuum Cleaning</li>
                                        <li><i className="far fa-check-circle"></i>Exterior Cleaning</li>
                                        <li><i className="far fa-check-circle"></i>Interior Wet Cleaning</li>
                                        <li><i className="far fa-times-circle"></i>Window Wiping</li>
                                    </ul>
                                </div>
                                <div className="price-footer">
                                    <Link className="btn btn-custom" to="/appointment">Book Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 animate__animated animate__zoomIn animate__slower animate__repeat-1 animate__delay-2s">
                            <div className="price-item featured-item">
                                <div className="price-header ">
                                    <h3>First class</h3>
                                    <h2 style={{ color: "purple" }}><span>$</span><strong>{firstclassName}</strong><span>.99</span></h2>
                                </div>
                                <div className="price-body">
                                    <ul>
                                        <li><i className="far fa-check-circle"></i>Seats Washing</li>
                                        <li><i className="far fa-check-circle"></i>Vacuum Cleaning</li>
                                        <li><i className="far fa-check-circle"></i>Exterior Cleaning</li>
                                        <li><i className="far fa-check-circle"></i>Interior Wet Cleaning</li>
                                        <li><i className="far fa-check-circle"></i>Window Wiping</li>
                                    </ul>
                                </div>
                                <div className="price-footer">
                                    <Link className="btn btn-custom" to="/appointment">Book Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Price End */}




            {/* Team Start */}
            <div className="team">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Meet Our Team</p>
                        <h2>Team Members</h2>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-3 col-md-6">
                            <div className="team-item">
                                <div className="team-img">
                                    <Link to="https://www.instagram.com/theanishgrover?igsh=ZWJtdHkza3dxaDR3">
                                        <img className="img-fluid " src="/img/anish.jpg" alt="anish" />
                                    </Link>
                                </div>
                                <div className="team-text">
                                    <h2>Anish Grover</h2>
                                    <div className="team-social">
                                        <Link to="https://www.instagram.com/theanishgrover?igsh=ZWJtdHkza3dxaDR3"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="team-item">
                                <div className="team-img ">
                                    <Link to={"https://www.instagram.com/_thegrownupkid?igsh=a3dscG1pbzhkcDN2&utm_source=qr"}>
                                    <img height={"305px"} src="/img/ashu.jpg" alt="ashutosh" /></Link>
                                </div>
                                <div className="team-text">
                                    <h2>Ashutosh Kamra</h2>
                                    <div className="team-social">
                                        <Link to="https://www.instagram.com/_thegrownupkid?igsh=a3dscG1pbzhkcDN2&utm_source=qr"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Team End */}
        </>
    )
}