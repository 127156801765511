import { Link } from "react-router-dom";

export default function Footer(){
    return(
        <>
        {/* Footer Start */}
        <div className="footer ">
            <div className="container d-flex justify-content-center  ">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-contact">
                            <h2>Get In Touch</h2>
                            <p><i className="fa fa-map-marker-alt"></i>449 Bateson St, Temiskaming Shores, ON P0J 1P0, Canada</p>
                            <p  ><Link style={{textDecoration:"none",color:"white"}}  to={""} > <i  className="fa fa-phone-alt"></i>+1(705)622-2001</Link></p>
                            <p><i className="fa fa-envelope"></i>Carport.asd@gmail.com</p>
                            <div className="footer-social">
                                <a className="btn" href=""><i className="fab fa-youtube"></i></a>
                                <Link className="btn" to="https://www.instagram.com/carport.asd?igsh=MWtvOHN4Z2poMHNwbg=="><i className="fab fa-instagram"></i></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-link">
                            <h2>Popular Links</h2>
                            <Link to="/about">About Us</Link>
                            <Link to="/contact">Contact Us</Link>
                            <Link to="/services">Our Service</Link>
                            <Link to="/price">Pricing Plan</Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-link">
                            <h2>Our Services</h2>
                            <Link to="/price">Interior Washing</Link>
                            <Link to="/price">Exterior Washing</Link>
                            <Link to="/price">Seat Washing</Link>
                            <Link to="/price">Window Wiping</Link>
                            <Link to="/price">Wet Cleaning</Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="row">
                            <div className="col">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2694.3989839581577!2d-79.6614736!3d47.52109480000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d267b59dc87f90b%3A0x6c11e144087ae362!2sCarport%20Auto%20Sale%20And%20Detailing!5e0!3m2!1sen!2sin!4v1706872960352!5m2!1sen!2sin" style={{width:"300px",height:"200px"}}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container copyright">
                <p>&copy; <Link to="/">Carport</Link>, All Right Reserved</p>
            </div>
        </div>
        {/* Footer End */}
        
        {/* Back to top button */}
        <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>            

        
        </>
    )
}