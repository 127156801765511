import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Services from "./pages/Services";
import Price from "./pages/Price";
import Contact from "./pages/Contact";
import About from "./pages/About";
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/js/dist/carousel"
import Appointment from "./pages/Appointment";
import Master from "./layout/Master";
import AdminMaster from "./Admin/AdminMaster";
import Login from "./pages/Login";
import Admincontact from "./Admin/Admincontact";
import Adminappointments from './Admin/AdminAppointments';
import DashBoard from './Admin/Dashboard';
import Pricesetting from './Admin/Pricesetting';
import Register from './pages/Register';
import Pagenotfound from './pages/Pagenotfound';
import Alluser from './Admin/Alluser';
import PriceUpdate from './Admin/PriceUpdate';
import AddPlan from './Admin/AddPlan';
import Forgetpwd from './pages/forgotpassword';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Master/>}>
          <Route path="/" element={<Home/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/price" element={<Price/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/appointment" element={<Appointment/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path='/forgotpassword' element={<Forgetpwd/>}/>
          <Route path='/register' element={<Register/>}/>
        </Route>
        <Route path="/admin" element={<AdminMaster/>}>
           <Route path='/admin' element={<DashBoard/>}/> 
           <Route path='/admin/pricesetting' element={<Pricesetting/>}/>
           <Route path='/admin/price/update/:id' element={<PriceUpdate/>}/>
           <Route path='/admin/admincontact' element={<Admincontact/>}/> 
           <Route path='/admin/adminappointments' element={<Adminappointments/>}/>
           <Route path='/admin/alluser' element={<Alluser/>}/>
           <Route path='/admin/addplan'element={<AddPlan/>}/>
        </Route>
        <Route path='*' element={<Pagenotfound/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
