import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import Apiservices from '../API/Apiservices';
import { toast } from 'react-toastify';
export default function Modalxox() {
    const [load,setload]=useState(false)
    const [planName,setplanName]=useState("")
    const [price,setprice]=useState("")
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
  
    useEffect(()=>{
        setIsOpen(true)
    },[load])

    Apiservices.planAll().then((res)=>{
        setplanName(res.data.data[2].planname)
        setprice(res.data.data[2].actualprice)
    }).catch((err)=>{
        toast.error("error"+err)
    })

    function afterOpenModal() {
      subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex:999
        },
      }
    return (
        <>
            <div>
                {/* <button onClick={setIsOpen}>Open Modal</button> */}
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    
                    <i style={{fontSize:"30px"}} onClick={closeModal} className="bi bi-x-circle-fill float-right "></i>
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{planName}</h2>
                    <form>
                        <img className='img-fluid pt-2' style={{height:"300px"}} src='/img/carwash.jpg'/>
                    </form>
                    <div className='fs-1 text-center'>${price}.<sup>99</sup> onwards</div>
                    <div className='text-end'>Exterior Wash only*</div>
                </Modal>
            </div>
        </>
    )
}